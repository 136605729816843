import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import copiedIcon from '../assets/images/check-white.svg'
import copyIcon from '../assets/images/copy-white.svg'
import { useLocation } from '@reach/router';


const Contact = ({}) => {
    const [copied, setCopied] = useState(false)
    const location = useLocation()
    const pathname = location.pathname
    const borrowersPath = '/borrowers/'
    const aboutPath = '/about/'


    return (
    <section className={`section-contact ${(pathname === borrowersPath) && 'borrowers-contact'} ${pathname === aboutPath && 'about-contact'}`}>
      <div className="container contact-container">
        <div className="panel-contact-rounded">
          <div className="status-notification">
            <span className="info">GET IN TOUCH</span>
          </div>
          <p>
            Need a specialized use case? Got a bottleneck in your organization’s processing of large volumes of data? Talk to us. Our adaptive architecture may be able to address your needs.
          </p> 
            <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 300 }}
                overlay={
                <Tooltip id="button-tooltip" style={{marginLeft: 20}}>
                {copied ?
                <span><img src={copiedIcon} alt="copied" className='copied-icon' style={{width: 16}} /> Email copied!</span>
            : <span><img src={copyIcon} alt="copy" className='copy-icon' style={{width: 18}} /> Click to copy</span>
            }
              </Tooltip>}
                >
                <CopyToClipboard text="contact@silta.finance">
                    <button className="email-link" onClick={() => setCopied(true)}>
                        contact@silta.finance
                    </button>
                </CopyToClipboard>
            </OverlayTrigger>
        </div>
      </div>
      <div className="d-sm-none d-xs-block">
        <div className="panel-contact-rounded">
          <div className="status-notification">
            <span className="info">GET IN TOUCH</span>
          </div>
            <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 300 }}
                overlay={
                <Tooltip id="button-tooltip" style={{marginLeft: 20}}>
                {copied ?
                <span><img src={copiedIcon} alt="copied" className='copied-icon' style={{width: 16}} /> Email copied!</span>
            : <span><img src={copyIcon} alt="copy" className='copy-icon' style={{width: 18}} /> Click to copy</span>
            }
              </Tooltip>}
                >
                <CopyToClipboard text="contact@silta.finance">
                    <button className="email-link" onClick={() => setCopied(true)}>
                        contact@silta.finance
                    </button>
                </CopyToClipboard>
            </OverlayTrigger>
        </div>
      </div>


    </section>
  )
}

export default Contact