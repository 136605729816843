import React, { useEffect } from 'react'
import DownloadIcon from '../assets/images/download.svg'
// import ExternalLinkIcon from '../assets/images/external-link-white.svg'
import { useLocation } from '@reach/router';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  // const siltaWhitePaperUrl = '/whitepaper'
  const dAppUrl = 'https://app.silta.finance'

  const location = useLocation()
  const pathname = location.pathname

  const borrowersPath = '/borrowers/'
  const aboutUsPath = '/about/'
  const homePath = '/'
  const noMobileLink = '/noMobileMarketplace/'

  useEffect(()=>{

    gsap.set('.section-lead .section-main', { marginTop: "20vh" })

    ScrollTrigger.create({
      trigger: '.fixed-header',
      start: "top top",
      end: '+=' + (600 + window.innerHeight),
      pin: true,
      pinSpacing: false
    });

      ScrollTrigger.refresh();

  }, [])

  return (
    <section id='hero' className={(pathname === borrowersPath && 'borrowers-hero') || (pathname === aboutUsPath && 'hero-about') || (pathname === homePath &&'hero')}
    >
      <div className="container" data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
          <div className={ pathname === aboutUsPath ? 'margin-about' : 'margin'}/>
          <div className={((pathname === borrowersPath ) ? '' : 'hero-text-container')}>
         {
           pathname === borrowersPath &&
           <>
           <h1 className='bold-text'>
           The path to infrastructure financing starts here. <br/>
           </h1>
           <p className='borrowers-subtitle'>
            Apply for an assessment of project viability and impact and connect to a network of financiers.
           </p>
           {/*
           <a href={dAppUrl} className="btn btn-outline btn-hero btn-hero-marketplace btn-hero-marketplace-outline d-xs-none d-sm-none d-md-flex" target="_blank" rel="noreferrer">
              LAUNCH APP
              <svg className="ml-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83301 10.1668L10.1663 1.8335M10.1663 1.8335H1.83301M10.1663 1.8335V10.1668" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a>
            <a href={noMobileLink} className='btn btn-outline btn-hero btn-hero-marketplace btn-hero-marketplace-outline d-xs-flex d-md-none'>
              LAUNCH APP
              <svg className="ml-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83301 10.1668L10.1663 1.8335M10.1663 1.8335H1.83301M10.1663 1.8335V10.1668" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a>
             */}
           </>
         }

         {
           pathname === aboutUsPath &&
           <>
           <h1 className='about-title'>
           Silta AI redefines due diligence and accelerates complex analysis.
          </h1>
          <div className='hero-btn-container'>
            {/* <a href={siltaWhitePaperUrl} className="btn btn-outline btn-hero d-flex mr-3" target="_blank" rel="noreferrer">
              DOWNLOAD THE WHITEPAPER
              <img src={DownloadIcon} alt='download whitepaper' className='download-icon ml-2' />
            </a> */}
          </div>
          </>
         }
         {
           pathname === homePath &&
           <>
           <h1>
           <span className="purple-text">Silta AI</span>: Accelerating<br/>Decision-Making.
           </h1>
           <p>Silta AI delivers fast, reliable due diligence, sustainability assessments and risk analysis.</p>

           <div className='hero-btn-container'>
            {/*
          <a href={dAppUrl} className="btn btn-primary btn-hero btn-dapp d-xs-none d-sm-none d-md-flex" target="_blank" rel="noreferrer">
            LAUNCH APP <svg className="ml-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83301 10.1668L10.1663 1.8335M10.1663 1.8335H1.83301M10.1663 1.8335V10.1668" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
          </a>
          <a href={noMobileLink} className='btn btn-primary btn-hero btn-dapp btn-dapp-xs d-flex d-sm-flex align-items-center d-md-none'>
            LAUNCH APP
              <svg className="ml-2" width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.83301 10.1668L10.1663 1.8335M10.1663 1.8335H1.83301M10.1663 1.8335V10.1668" stroke="white" strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </a>
             */}
          {/* <a href='https://test.app.silta.finance/lend' className="btn btn-primary btn-hero ml-4 btn-dapp" target="_blank" rel="noreferrer">
          OPEN SILTA DAPP <span><img src={ExternalLinkIcon} alt="external link" className="material-icons external-link-icon" /></span>
        </a> */}
        </div>
           </>
         }
        </div>
        {/* {
          pathname === home && pathname !== aboutUs &&
        <div className='hero-btn-container'>
          <a href={siltaWhitePaperUrl} className="btn btn-outline btn-hero" target="_blank" rel="noreferrer">
            DOWNLOAD THE WHITEPAPER
            <img src={DownloadIcon} alt='download whitepaper' className='download-icon ml-2' />
          </a>
          <a href='https://test.app.silta.finance/lend' className="btn btn-primary btn-hero ml-4 btn-dapp" target="_blank" rel="noreferrer">
          OPEN SILTA DAPP <span><img src={ExternalLinkIcon} alt="external link" className="material-icons external-link-icon" /></span>
        </a>
        </div>
        } */}
      </div>
    </section>
  )
}

export default Hero